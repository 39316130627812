import InfoDisabled from '../../../../components/info-disabled';
import { BillingDetails } from '../../../../types/transaction.types';
import './transaction-details.part.billing-information.scss';

interface TransactionDetailsPartBillingInformationProps {
    billingDetails?: BillingDetails;
}

const TransactionDetailsPartBillingInformation: React.FC<TransactionDetailsPartBillingInformationProps> = ({ billingDetails }) => {
    return (
        <div className='transaction-details-part-billing-information flex-column flex-gap-50'>
            <div className='flex-column flex-gap-20 sm-flex-wrap'>
                <div className='flex-row flex-gap-20 sm-flex-wrap'>
                    <InfoDisabled label='Name' value={`${billingDetails?.firstName || ''} ${billingDetails?.lastName || ''}`} />
                    <InfoDisabled label='Country' value={billingDetails?.country || 'N/A'} />
                    <div className='flex-1-form'></div>
                </div>
                <div className='flex-row flex-gap-20 sm-flex-wrap'>
                    <InfoDisabled label='City' value={billingDetails?.city || 'N/A'} />
                    <InfoDisabled label='Street' value={billingDetails?.addressLine1 || 'N/A'} />
                    <InfoDisabled label='House Number' value={billingDetails?.addressLine2 || 'N/A'} />
                </div>
                <div className='flex-row flex-gap-20 sm-flex-wrap'>
                    <InfoDisabled label='Postal Code' value={billingDetails?.postalCode || 'N/A'} />
                    <InfoDisabled label='Email' value={billingDetails?.email || 'N/A'} />
                    <InfoDisabled label='Phone' value={billingDetails?.phoneNumber || 'N/A'} />
                </div>
            </div>
        </div>
    );
};

export default TransactionDetailsPartBillingInformation;
