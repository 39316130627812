import React, { HTMLInputTypeAttribute, SVGProps, useRef } from 'react';
import './app-input.scss';
import { ForwardedProps } from '../../../types/component-props.types';
import { formatAmount } from '../../../utils/number-utils';

interface AppFormInputProps extends ForwardedProps {
  theme?: 'auth' | 'filter';
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  onBlur?: () => void;
  onChange?: (e?: any) => void;
  value?: any;
  readOnly?: boolean;
  icon?: React.ReactElement<SVGProps<SVGSVGElement>>;
  min?: number;
  hasCurrency?: boolean;
}

const AppInput: React.FC<AppFormInputProps> = (
  {
    theme,
    type = 'text',
    value,
    placeholder,
    onBlur,
    onChange,
    id,
    readOnly,
    icon,
    min,
    hasCurrency = false
  }
) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const focusInput = () => {
    inputRef.current?.focus();
  };

  const formatCurrency = (value: number): string => {

    const formattedValue = value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });
    return formattedValue;
  };

  const limitToTwoDecimals = (value: string): string => {
    const numericValue = parseFloat(value);
    return isNaN(numericValue) ? value : numericValue.toFixed(2);
  };

  const handleNumericChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputElement = e.target;
    const inputValue = inputElement.value;

    const cursorPosition = inputElement.selectionStart || 0;
    const rawValueBeforeCursor = inputValue.slice(0, cursorPosition).replace(/[^0-9.]/g, '');
    const cursorDecimalOffset =
      rawValueBeforeCursor.indexOf('.') !== -1
        ? rawValueBeforeCursor.length - rawValueBeforeCursor.indexOf('.') - 1
        : -1;

    let numericValue = parseFloat(inputValue.replace(/[^0-9.]/g, '')) || 0;
    let formattedValue: string;

    if (hasCurrency) {
      formattedValue = formatCurrency(numericValue);
    } else {
      formattedValue = numericValue
        .toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }

    if (onChange) {
      onChange({
        ...e,
        target: { ...e.target, value: numericValue },
      });
    }

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.value = formattedValue;

        let newCursorPosition = 0;
        let rawDigitCount = 0;
        for (let i = 0; i < formattedValue.length; i++) {
          if (formattedValue[i].match(/[0-9]/)) {
            rawDigitCount++;
          }

          if (
            rawDigitCount === rawValueBeforeCursor.length ||
            (cursorDecimalOffset >= 0 &&
              rawDigitCount === rawValueBeforeCursor.indexOf('.') + cursorDecimalOffset)
          ) {
            newCursorPosition = i + 1;
            break;
          }
        }

        inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
      }
    }, 0);
  };

  const handleStandardChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;

    if (type === 'number') {
      if (min !== undefined && +inputValue < min) {
        inputValue = String(min);
      }
      inputValue = limitToTwoDecimals(inputValue);

      if (onChange) {
        onChange({
          ...e,
          target: { ...e.target, value: parseFloat(inputValue) }
        });
      }
    } else {
      if (onChange) {
        onChange(e);
      }
    }
  };

  const handleChange = type == 'number' ? handleNumericChange : handleStandardChange;

  return (
    <div
      className={`app-input form-field-container ${theme ? theme : ''} ${icon ? 'hasIcon' : ''}`}
      onClick={focusInput}
    >
      {icon && icon}
      <input
        ref={inputRef}
        id={id}
        type={hasCurrency || type == 'number' ? 'text' : type}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={handleChange}
        value={
          hasCurrency || type === 'number'
            ? value != null && !isNaN(value)
              ? hasCurrency
                ? formatCurrency(parseFloat(limitToTwoDecimals(String(value))))
                : parseFloat(limitToTwoDecimals(String(value))).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : ''
            : value ?? ''
        }
        readOnly={readOnly}
      />
    </div>
  );
};

export default AppInput;
