import React, { useEffect, useState } from "react";
import { ReactComponent as RefundIcon } from '../assets/icons/refund.svg';
import "./add-comment-modal.scss";
import AppInput from "../../../../components/form/base/app-input";
import AppTextArea from "../../../../components/form/base/app-textarea";
import { apiService } from "../../../../services/api.service";
import { log } from "console";

interface AddCommentModalProps {
  text: string;
  id: string;
  refundReason: string;
  confirmLabel: string;
  cancelLabel: string;
  confirmAction:  (reason: string) => Promise<void>;
  cancelAction: () => void;
  isVisible: boolean;
}

const AddCommentModal: React.FC<AddCommentModalProps> = ({
  text,
  id,
  refundReason,
  confirmLabel,
  cancelLabel,
  confirmAction,
  cancelAction,
  isVisible,
}) => {



    const [ reason, setReason ] = useState('');

    
    useEffect(() => {
        setReason(refundReason)
    }, [isVisible])

    const addReason = async () => { 
        try {
            setReason('')
            await confirmAction(reason)
        } catch (e:any) {
            console.log(e.response);
            
        }
    } 


  if (!isVisible) return null;


  return (
    <div className="add-comment-modal-overlay">
      <div className="app-modal">
        <div className="modal-icon">
          {/* <RefundIcon color={statusColor} /> */}
        </div>
        <div className="modal-content">
          <p className="modal-text">
            Transaction Id: {id}
            {/* <span style={{ color: statusColor }}>{merchantName}</span>? */}
          </p>
          <p className="modal-id">{text}</p>
          <AppTextArea value={reason} onChange={(value) => {setReason(value.target.value)}}/>
        </div>
        <div className="modal-actions">
          <button
            className="btn btn-cancel"
            onClick={cancelAction}>
            {cancelLabel}
          </button>
          <button
            className="btn btn-confirm"
            onClick={() => addReason()}
            // style={{ backgroundColor: statusColor }}
          >
            {confirmLabel}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddCommentModal;
