import { Navigate, RouteObject } from "react-router-dom";
import DashboardPage from "./dashboard/dashboard-page";
import merchantsRouter from "./merchants/merchants-router";
import processorsRouter from "./processors/processors-router";
import transactionsRouter from "./transactions/transactions-router";
import dashboardRouter from "./dashboard/dashboard-router";
import MerchantGuard from "./merchant-guard";
import ThreedSecurePage from "./3ds/3ds-page";
// import PayerSetupPage from "./payer-setup.tsx/payer-setup-page";
import Step1Page from "./payer-setup.tsx/step-1/step-1-page";

const guardedProcessorsRouter = processorsRouter.map(route => ({
    ...route,
    element: (
        <MerchantGuard>
            {route.element}
        </MerchantGuard>
    )
}));


const privateRouter: RouteObject[] = [
    {
        path: '',
        element: <Navigate to="dashboard" replace />, 
    },
    {
        path: 'dashboard',
        children: dashboardRouter
    },
    {
        path: 'transactions',
        children: transactionsRouter
    },
    {
        path: 'merchants',
        children: merchantsRouter
    },
    
    {
        path: 'processors',
        children: guardedProcessorsRouter
    },
]

export default privateRouter;