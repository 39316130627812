import { useState } from 'react';
import { ReactComponent as ExcelIcon } from '../../../../assets/icons/excel.svg';
import { ReactComponent as PdfIcon } from '../../../../assets/icons/pdf.svg';
import './reports-merchant-page.part.filtered-data-item.scss';
import LoadingSpinner from '../../../../components/loading-spinner';
import { apiService } from '../../../../services/api.service';
import { TransactionFilters } from '../../../../services/api.service-types';
import { errorService } from '../../../../services/error.service';
import Button from '../../../../components/button';

interface ReportsMerchantPartFilteredDataItemProps {
    total: number;
    noTransactions: number;
    filters: Partial<TransactionFilters>;
}

const ReportsMerchantPartFilteredDataItem: React.FC<ReportsMerchantPartFilteredDataItemProps> = ({
    total,
    noTransactions,
    filters,
}) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const [downloadType, setDownloadType] = useState<'excel' | 'pdf' | null>(null);

    const handleDownload = async (fileType: 'excel' | 'pdf') => {
        try {
            setIsDownloading(true);
            setDownloadType(fileType);

            const response = fileType === 'excel'
                ? await apiService.generateSettlementExcel(filters)
                : await apiService.generatePdf(filters);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileType === 'excel' ? 'transactions.xlsx' : 'transactions.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error: any) {
            console.log(error.code);
            if (error.code == 'ECONNABORTED') {
                errorService.showError('Download failed', 'Please select a smaller interval');
            } else {
                errorService.showApiError(error);
            }
        } finally {
            setIsDownloading(false);
        }
    };

    return (
        <div className="reports-merchant-part-filtered-data-item flex-column flex-gap-20 flex-1">
            <div className="description">
                Number of Filtered Transactions: <span className="value">{noTransactions}</span>
            </div>
            <div className="flex-row flex-align-space-between-center flex-gap-5">
                <Button
                    theme="accent"
                    label="Download Excel"
                    icon={ExcelIcon}
                    onClick={() => handleDownload('excel')}
                    disabled={isDownloading}
                />
                <Button
                    theme="accent"
                    label="Download PDF"
                    icon={PdfIcon}
                    onClick={() => handleDownload('pdf')}
                    disabled={isDownloading}
                />

            </div>

            {isDownloading && (
                <div className="downloading-text flex-row flex-align-center">
                    <LoadingSpinner />
                    <span>Downloading {downloadType === 'excel' ? 'Excel' : 'PDF'}...</span>
                </div>
            )}
        </div>
    );
};

export default ReportsMerchantPartFilteredDataItem;
