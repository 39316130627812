import { ErrorBoundary } from "react-error-boundary";

const ErrorFallback = ({ error, resetErrorBoundary }: { error: Error, resetErrorBoundary: () => void }) => (
  <div role="alert">
    <h2>Something went wrong</h2>
    <p>{error.message}</p>
    <button onClick={resetErrorBoundary}>Try again</button>
  </div>
);

export default function AppErrorFallbackPage({ children }: { children: React.ReactNode }) {
    console.log(children);
    
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
      }}
    >
      {children}
    </ErrorBoundary>
  );
}
