import React, { useState, useEffect } from "react";
import { ReactComponent as ArrowIcon } from "../../../../assets/icons/arrow.svg";
import "./reports-daily-mobile.part.week-info.scss";
import { formatAmount } from "../../../../utils/number-utils";

interface Merchant {
  merchantId: string;
  merchantName: string;
  approvedTransactionsNo: number;
  approvedTransactionsAmount: number;
  approvedTransactionsPercent: number | null;
  declinedTransactionsNo: number;
  declinedTransactionsAmount: number;
  declinedTransactionsPercent: number | null;
}

interface DayData {
  dayNo: number;
  dayName: string;
  merchants: Merchant[];
}

interface ReportsDailyMobilePartWeekInfoProps {
  data: DayData[];
}

const ReportsDailyMobilePartWeekInfo: React.FC<ReportsDailyMobilePartWeekInfoProps> = ({
  data,
}) => {
  const splitIntoWeeks = (days: DayData[]): DayData[][] => {
    const weeks: DayData[][] = [];
    let currentWeek: DayData[] = [];

    days.forEach((day, index) => {
      currentWeek.push(day);
      if (day.dayName === "Sunday" || index === days.length - 1) {
        weeks.push(currentWeek);
        currentWeek = [];
      }
    });

    return weeks;
  };

  const getCurrentWeekIndex = (weeks: DayData[][]): number => {
    const today = new Date();
    const todayDate = today.getDate();

    for (let i = 0; i < weeks.length; i++) {
      const week = weeks[i];
      if (week.some((day) => day.dayNo === todayDate)) {
        return i;
      }
    }

    return 0; 
  };

  const weeks = splitIntoWeeks(data);
  
  const [currentWeekIndex, setCurrentWeekIndex] = useState(getCurrentWeekIndex(weeks));
  const [animationClass, setAnimationClass] = useState<string>("");

  const handleNextWeek = () => {
    if (currentWeekIndex < weeks.length - 1) {
      setCurrentWeekIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePreviousWeek = () => {
    if (currentWeekIndex > 0) {
      setCurrentWeekIndex((prevIndex) => prevIndex - 1);
    }
  };

  useEffect(() => {
    setAnimationClass("fade-in");
    const timer = setTimeout(() => {
      setAnimationClass("");
    }, 300);

    return () => clearTimeout(timer);
  }, [currentWeekIndex]);

  const currentWeek = weeks[currentWeekIndex];

  const calculateTotals = (week: DayData[]) => {
    const merchantTotals: Record<string, number> = {};
    let grandTotal = 0;

    week.forEach((day) => {
      day.merchants.forEach((merchant) => {
        if (!merchantTotals[merchant.merchantName]) {
          merchantTotals[merchant.merchantName] = 0;
        }
        merchantTotals[merchant.merchantName] += merchant.approvedTransactionsAmount;
        grandTotal += merchant.approvedTransactionsAmount;
      });
    });

    return { merchantTotals, grandTotal };
  };

  const { merchantTotals, grandTotal } = calculateTotals(currentWeek);

  return (
    <div className="reports-daily-mobile-week-info">
      <div className={`card ${animationClass}`}>
        <div className="card-header">
          <button
            onClick={handlePreviousWeek}
            disabled={currentWeekIndex === 0}
            className="arrow-btn"
          >
            <ArrowIcon className="left" />
          </button>
          <span className="card-title">Week {currentWeekIndex + 1}</span>
          <button
            onClick={handleNextWeek}
            disabled={currentWeekIndex === weeks.length - 1}
            className="arrow-btn"
          >
            <ArrowIcon className="right" />
          </button>
        </div>
        <div className="card-body">
          <div className="merchant-totals">
            {Object.entries(merchantTotals).map(([merchantName, total]) => (
              <div key={merchantName} className="merchant-total">
                <p>
                  <strong>{merchantName}</strong>: <span className="value"> ${formatAmount(total)} </span>
                </p>
              </div>
            ))}
          </div>
          <div className="grand-total">
            <p>
              <strong>Grand Total:</strong> <span className="value"> ${formatAmount(grandTotal)} </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportsDailyMobilePartWeekInfo;
