import React, { useState, useEffect } from "react";
import { ReactComponent as ArrowIcon } from '../../../../assets/icons/arrow.svg';
import dateService from "../../../../services/date.service";
import './reports-daily-mobile.part.day-info.scss';
import { formatAmount } from "../../../../utils/number-utils";


interface Merchant {
  merchantId: string;
  merchantName: string;
  approvedTransactionsNo: number;
  approvedTransactionsAmount: number;
  approvedTransactionsPercent: number | null;
  declinedTransactionsNo: number;
  declinedTransactionsAmount: number;
  declinedTransactionsPercent: number | null;
}

interface DayData {
  dayNo: number;
  dayName: string;
  merchants: Merchant[];
}

interface ReportsDailyMobilePartDayInfoProps {
  data: DayData[];
  isCurrentMonth: boolean
}

const ReportsDailyMobilePartDayInfo: React.FC<ReportsDailyMobilePartDayInfoProps> = ({ data, isCurrentMonth }) => {
  const [currentDayIndex, setCurrentDayIndex] = useState(isCurrentMonth ? dateService.currentDayNumber()-1 : 0);
  const [animationClass, setAnimationClass] = useState<string>("");

  const handleNextDay = () => {
    if (currentDayIndex < data.length - 1) {
      setCurrentDayIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePreviousDay = () => {
    if (currentDayIndex > 0) {
      setCurrentDayIndex((prevIndex) => prevIndex - 1);
    }
  };

  useEffect(() => {
    setAnimationClass("fade-in"); 
    const timer = setTimeout(() => {
      setAnimationClass(""); 
    }, 300); 

    return () => clearTimeout(timer);
  }, [currentDayIndex]);

  const currentDay = data[currentDayIndex];

  return (
    <div className="reports-daily-mobile-day-info">
      <div className={`card ${animationClass}`}>
        <div className="card-header">
          <button
            onClick={handlePreviousDay}
            disabled={currentDayIndex === 0}
            className="arrow-btn"
          >
            <ArrowIcon className="left"/>
          </button>
          <span className="card-title">
            {currentDay.dayName} {currentDay.dayNo}
          </span>
          <button
            onClick={handleNextDay}
            disabled={currentDayIndex === data.length - 1}
            className="arrow-btn"
          >
            <ArrowIcon className="right"/>
          </button>
        </div>
        <div className="card-body">
          {currentDay.merchants.map((merchant) => (
            <div key={merchant.merchantId} className="merchant-data">
              <p className="merchant-name">
                <strong>{merchant.merchantName}</strong>
              </p>
              <p>
                Approved Transactions No:{" "}
                <span className="value">{merchant.approvedTransactionsNo}</span>
              </p>
              <p>
                Approved Transactions Amount:{" "}
                <span className="value approved">
                  ${formatAmount(merchant.approvedTransactionsAmount)}
                </span>
              </p>
              <p>
                Declined Transactions No:{" "}
                <span className="value">{merchant.declinedTransactionsNo}</span>
              </p>
              <p>
                Declined Transactions Amount:{" "}
                <span className="value declined">
                  ${formatAmount(merchant.declinedTransactionsAmount)}
                </span>
              </p>
              <p>
                Approved Transactions Percent:{" "}
                <span className="value">
                  {merchant.approvedTransactionsPercent !== null
                    ? `${merchant.approvedTransactionsPercent}%`
                    : "-"}
                </span>
              </p>
             
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReportsDailyMobilePartDayInfo;
