
import InfoDisabled from '../../../../components/info-disabled';
import './transaction-details.part.payment-information.scss';

interface OrderInformation {
    merchantId: string;
    merchantName: string;
    accountId: string;
    accountName: string;
    amount: string;
    orderId: string;
    merchantOrderId: string;
    requestTime: string;
    transactionReferenceCode: string;
}

interface PaymentInformation {
    totalAuthorizedAmount: number;
    totalCapturedAmount: number;
    name: string;
    cardNo: string;
    cardType: string;
    expiryDate: string;
    refundReason?: string;
}

interface TransactionDetailsPartPaymentInformationProps {
    orderInformation: OrderInformation;
    paymentInformation: PaymentInformation;
}

const TransactionDetailsPartPaymentInformation: React.FC<TransactionDetailsPartPaymentInformationProps> = ({
    orderInformation,
    paymentInformation,
}) => {
    return (
        <div className="transaction-details-part-payment-information flex-column flex-gap-50">
            <div className="flex-column flex-gap-20 sm-flex-wrap">
                <div className="section-subtitle">Order Information</div>
                <div className="flex-row flex-gap-20 sm-flex-wrap">
                    <InfoDisabled label="Merchant ID" value={orderInformation.merchantId} />
                    <InfoDisabled label="Merchant Name" value={orderInformation.merchantName} />
                    <InfoDisabled label="Merchant Order ID" value={orderInformation.merchantOrderId} />
                </div>
                <div className="flex-row flex-gap-20 sm-flex-wrap">
                    <InfoDisabled label="Account ID" value={orderInformation.accountId} />
                    <InfoDisabled label="Account Name" value={orderInformation.accountName} />
                    <InfoDisabled label="Amount (USD)" value={orderInformation.amount} />
                </div>
                <div className="flex-row flex-gap-20 sm-flex-wrap">
                    <InfoDisabled label="Order ID" value={orderInformation.orderId} />
                    <InfoDisabled label="Request Time" value={orderInformation.requestTime} />
                    <InfoDisabled label="Transaction Reference Code" value={orderInformation.transactionReferenceCode} />
                </div>
            </div>

            <div className="flex-column flex-gap-20 sm-flex-wrap">
                <div className="section-subtitle">Pay Information</div>
                <div className="flex-row flex-gap-20 sm-flex-wrap">
                    <InfoDisabled label="Total Authorized Amount" value={`${paymentInformation.totalAuthorizedAmount}`} />
                    <InfoDisabled label="Total Captured Amount" value={`${paymentInformation.totalCapturedAmount}`} />
                    <div className="flex-1-form"></div>
                </div>
                <div className="flex-row flex-gap-20 sm-flex-wrap">
                    <InfoDisabled label="Name" value={paymentInformation.name} />
                    <InfoDisabled label="Card No" value={paymentInformation.cardNo} />
                    <InfoDisabled label="Card Type" value={paymentInformation.cardType} />
                </div>
                <div className="flex-row flex-gap-20 sm-flex-wrap">
                    <InfoDisabled label="Expiry Date" value={paymentInformation.expiryDate} />
                    <InfoDisabled label="Refund Reason" value={paymentInformation.refundReason || ''} />
                    <div className="flex-1-form"></div>
                </div>
            </div>
        </div>
    );
}

export default TransactionDetailsPartPaymentInformation