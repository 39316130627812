import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import AppFormInput from '../../../../components/form/app-form-input';
import ProcessorField from './edit-merchant.part.form.add-processor';
import AddElementButton from '../../../../components/form/add-element-button';
import Button from '../../../../components/button';
import { apiService } from '../../../../services/api.service';
import { useNavigate } from 'react-router-dom';
import './edit-merchant.part.form.scss';
import InfoDisabled from '../../../../components/info-disabled';
import { errorService } from '../../../../services/error.service';
import { useSelector } from 'react-redux';
import { UserState } from '../../../../store/user.slice';
import { AppStore } from '../../../../store/store';



export interface EditMerchantForm {
    name: string;
    midList: MID[];
}

interface MID {
    processorId?: string,
    new: boolean,
    midsDetails: MIDDetails[];
}

export interface MIDDetails {
    midName: string,
    trashHoldLimit?: number
    commissions?: CommissionFormField[];
    midCode: string;
    md5?: string;
    privateKey?: string;
    midDescriptor: string;
    valid: boolean;
    new: boolean;
}

export enum CommissionValueType {
    PERCENT = 'PERCENT',
    FIXED = 'FIXED'
}

export enum CommissionType {
    MDR = 'MDR',
    ROLLING = 'ROLLING',
    TRANSACTION = 'TRANSACTION',
    REFUND = 'REFUND',
    CHARGEBACK = 'CHARGEBACK',
    SETTLEMENT = 'SETTLEMENT'
}

interface CommissionFormField {
    value: number;
    valueType: CommissionValueType;
    type: CommissionType;
}

interface EditMerchantPartForm {
    id: string
}



const EditMerchantPartForm: React.FC<EditMerchantPartForm> = ({ id }) => {
    const navigate = useNavigate();
    const [initialMerchant, setInitialMerchant] = useState<any>();
    const { user } = useSelector<AppStore, UserState>((state) => state.user);
    const form = useForm<EditMerchantForm>({
        defaultValues: {
            midList: [
                {
                    processorId: '',
                    midsDetails: [
                        {
                            midCode: '',
                            commissions: Object.values(CommissionType).map((type) => ({
                                value: 0,
                                valueType: CommissionValueType.PERCENT,
                                type,
                                anyValue: false,
                            })),
                        },
                    ],
                },
            ],
        },
    });

    useEffect(() => {
        apiService
            .getMerchantById(id)
            .then((response) => {
                const mappedData: EditMerchantForm = {
                    name: response.data.merchant.lastName,
                    midList: response.data.processorsList.map((processor: any) => ({
                        processorId: processor.processorId,
                        new: false,
                        midsDetails: processor.midList.map((midItem: any) => ({
                            midName: midItem.mid.midName,
                            midCode: midItem.mid.midCode,
                            midDescriptor: midItem.mid.midDescriptor,
                            trashHoldLimit: midItem.mid.trashHoldLimit,
                            commissions: Object.values(CommissionType).map((type) => {
                                const existingCommission = midItem.commissions?.find(
                                    (commission: any) => commission.commissionType === type
                                );
                                return {
                                    value: existingCommission?.commissionAmount || 0,
                                    valueType: existingCommission?.isPercent
                                        ? CommissionValueType.PERCENT
                                        : CommissionValueType.FIXED,
                                    type,
                                };
                            }),
                            valid: midItem.mid.valid,
                            new: false,
                        })),
                    })),
                };
                setInitialMerchant(response.data)
                form.reset(mappedData);
            })
            .catch((e) => console.error(e));
    }, [id, user]);

    const { fields: processorFields, append: addProcessor, remove: removeProcessor } = useFieldArray({
        control: form.control,
        name: 'midList',
    });


    const onUpdateMerchant = async (data: EditMerchantForm) => {
        if (!initialMerchant) return;
        
        const transformedData = {
            merchant: {
                id: initialMerchant.merchant.id,
                lastName: data.name,
                email: initialMerchant.merchant.email,
                userName: initialMerchant.merchant.userName,
                isEnabled: initialMerchant.merchant.isEnabled,
            },
            processorsList: data.midList.map((processor) => {
                const existingProcessor = initialMerchant.processorsList.find(
                    (p: any) => p.processorId === processor.processorId
                );
                console.log(processor);
                
                return {
                    processorId: processor.processorId,
                    midList: processor.midsDetails.map((mid) => {
                        const existingMid = existingProcessor?.midList.find(
                            (m: any) => m.mid.midCode === mid.midCode
                        );
                        if (existingMid) {
                            return {
                                mid: {
                                    id: existingMid?.mid.id || null,
                                    processorId: processor.processorId,
                                    merchantId: initialMerchant.merchant.id,
                                    midCode: mid.midCode,
                                    midDescriptor: mid.midDescriptor,
                                    valid: mid.valid,
                                    trashHoldLimit: Number(mid.trashHoldLimit),
                                    midName: mid.midName,
                                },
                                commissions: mid?.commissions?.map((commission) => ({
                                    id: existingMid?.commissions?.find(
                                        (c: any) => c.commissionType === commission.type
                                    )?.id || null,
                                    midId: existingMid?.mid.id || null,
                                    commissionType: commission.type,
                                    commissionAmount: Number(commission.value),
                                    isPercent: commission.valueType === CommissionValueType.PERCENT,
                                    isFixed: commission.valueType === CommissionValueType.FIXED,
                                    // isAnyValue: commission.anyValue || false,
                                    fromAmount: 0,
                                    toAmount: 0,
                                })),
                            };
                        } else {
                            console.log(mid);
                            

                            return {
                                mid: {
                                    id: null,
                                    processorId: processor.processorId,
                                    merchantId: initialMerchant.merchant.id,
                                    midCode: mid.midCode,
                                    midDescriptor: mid.midDescriptor,
                                    md5: mid.md5,
                                    privateKey: mid.privateKey,
                                    valid: mid.valid,
                                    trashHoldLimit: Number(mid.trashHoldLimit),
                                    midName: mid.midName,
                                },
                                commissions: mid?.commissions?.map((commission) => ({
                                    id:  null,
                                    midId:  null,
                                    commissionType: commission.type,
                                    commissionAmount: Number(commission.value),
                                    isPercent: commission.valueType === CommissionValueType.PERCENT,
                                    isFixed: commission.valueType === CommissionValueType.FIXED,
                                    // isAnyValue: commission.anyValue || false,
                                    fromAmount: 0,
                                    toAmount: 0,
                                })),
                            };
                        }
                    }),
                };
            }),
        };

        try {
            await apiService.updateMerchant(transformedData);
            errorService.showSuccess('Success', `Merchant ${data.name} has been updated!`)
            navigate(-1);
        } catch (e) {
            errorService.showApiError(e)
        }
    }


    return (
        <div className="add-merchant-form flex-column flex-gap-30">
            <div className="flex-column flex-gap-15">
                <span className="label">General information</span>
                <div className="flex-row flex-gap-20 ">
                    <AppFormInput
                        control={form.control}
                        name="name"
                        placeholder="Name"
                        validations={{ required: true }}
                    />
                    <InfoDisabled label='Email' value={initialMerchant?.merchant?.email || ''} />
                </div>
            </div>

            <div className="flex-column flex-gap-15">
                <span className="label">Processors</span>

                {processorFields.map((processor, index) => (
                    <ProcessorField
                        key={processor.id}
                        form={form}
                        processorIndex={index}
                        removeProcessor={removeProcessor}
                    />
                ))}

                <div className='flex-row'>
                    <div className='flex-1-form'>
                        <AddElementButton
                            text="Add processor"
                            onClick={() =>
                                addProcessor([{
                                    new: true,
                                    midsDetails: [{
                                        midName: '',
                                        midCode: '',
                                        valid: true,
                                        midDescriptor: '',
                                        commissions: Object.values(CommissionType).map((type) => ({
                                            value: 0,
                                            valueType: CommissionValueType.PERCENT,
                                            type,
                                            anyValue: false,
                                        })),
                                        new: true
                                    }]
                                }])
                            }
                        />
                    </div>
                    <div className='flex-2-form'></div>
                </div>
            </div>


            <div className='flex-row flex-align-end-end flex-gap-40'>
                <Button
                    label='Cancel'
                    theme='secondary'
                    onClick={() => navigate(-1)}
                />
                <Button
                    label='Save'
                    disabled={!form.formState.isValid}
                    onClick={form.handleSubmit(onUpdateMerchant)}
                />
            </div>
        </div>
    );
};

export default EditMerchantPartForm