import { useNavigate } from 'react-router-dom';
import TimeFilters from '../../../../components/time-filters';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { apiService } from '../../../../services/api.service';
import { AppStore } from '../../../../store/store';
import { UserState } from '../../../../store/user.slice';
import { getColorByStatusType, getColorByCardBrandType, getRandomColor } from '../../../../types/statistics.types';
import StatisticSection from '../../../../components/statistics-section';
import StatisticsSectionBarChartType from '../../../../components/statistics-section.bar-chart-type';
import StatisticsSectionChartType from '../../../../components/statistics-section.chart-type';
import './reports-merchant-page.scss';
import { colorsPalette } from '../../../../utils/colors-utils';
import { LabelValue } from '../../../../types/label-value.types';
import AppSelect from '../../../../components/form/base/app-select';
import { TransactionSummary } from '../../../../types/transaction.types';
import TransactionsPartFilteredDataItem from '../../transactions/transactions-page.part.filtered-data-item';
import ReportsMerchantPartFilteredDataItem from './reports-merchant-page.part.filtered-data-item';


const ReportsMerchantPage: React.FC = () => {
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState<string>();
    const [endDate, setEndDate] = useState<string>();
    const [transactionsStatus, setTransactionsStatus] = useState();
    const [transactionsNumber, setTransactionsNumber] = useState();
    const [cardTypes, setCardTypes] = useState();
    const [processors, setProcessors] = useState();
    const [merchantOptions, setMerchantOptions] = useState<LabelValue[]>([]);
    const [selectedMerchant, setSelectedMerchant] = useState('');
    const [midOptions, setMidOptions] = useState<LabelValue[]>([]);
    const [selectedMID, setSelectedMID] = useState('');
    const [transactionsSummary, setTransactionsSummary] = useState<TransactionSummary>();

    const [merchants, setMerchants] = useState();
    const [mids, setMIDS] = useState();

    const { user } = useSelector<AppStore, UserState>((state) => state.user)

    const isMerchant = (user?.roles?.includes("merchant") || user?.roles?.includes("bas_merchant_view")) ?? false;



    useEffect(() => {

        apiService.getTransactionsSummary({ startDate, endDate, merchantId: selectedMerchant, midId: selectedMID })
            .then((response) => {
                setTransactionsSummary(response.data);
            })
            .catch((error) => console.error('Error fetching transactions summary:', error));

        apiService
            .getAllMerchants()
            .then((response) => {
                const transformedMerchants = response.data.map((merchant: any) => ({
                    label: merchant.lastName || merchant.lastName || 'Unknown',
                    value: merchant.id,
                }));
                setMerchantOptions([{
                    label: 'Reset',
                    value: '',
                }, ...transformedMerchants]);
            })
            .catch((error) => console.error('Error fetching merchants:', error));

        apiService.getTransactionsSummary({ startDate, endDate, merchantId: selectedMerchant, midId: selectedMID })
            .then((response) => { setTransactionsNumber(response.data.transactionCount) })
            .catch((error) => console.error('Error fetching transactions number:', error));


        apiService.getTransactionsStatusStatistics({ startDate, endDate, merchantId: selectedMerchant, midId: selectedMID })
            .then((response) => {
                const transformedData = response.data.map((item: any) => ({
                    color: getColorByStatusType(item.name),
                    name: item.name,
                    quantitativePercent: item.quantityAndQuality?.quantitativePercent || 0,
                    quantitativeValue: item.quantityAndQuality?.quantitativeValue || 0,
                    qualitativePercent: item.quantityAndQuality?.qualitativePercent || 0,
                    qualitativeValue: item.quantityAndQuality?.qualitativeValue || 0,
                }));
                setTransactionsStatus(transformedData);
            })
            .catch((error) => console.error('Error fetching transactions status:', error));

        apiService.getCardTypeStatistics({ startDate, endDate, merchantId: selectedMerchant, midId: selectedMID })
            .then((response) => {
                const transformedData = response.data.map((item: any) => ({
                    color: getColorByCardBrandType(item.name),
                    name: item.name,
                    quantitativePercent: item.quantityAndQuality?.quantitativePercent || 0,
                    quantitativeValue: item.quantityAndQuality?.quantitativeValue || 0,
                    qualitativePercent: item.quantityAndQuality?.qualitativePercent || 0,
                    qualitativeValue: item.quantityAndQuality?.qualitativeValue || 0,
                }));
                setCardTypes(transformedData);
            })
            .catch((error) => console.error('Error fetching card types:', error));

        apiService.getProcessorStatistics({ startDate, endDate, merchantId: selectedMerchant, midId: selectedMID })
            .then((response) => {
                const transformedData = response.data.map((item: any, index: number) => {
                    return ({
                        color: colorsPalette[index],
                        name: item.name,
                        type: isMerchant ? null : item.type,
                        quantitativePercent: item.quantityAndQuality?.quantitativePercent || 0,
                        quantitativeValue: item.quantityAndQuality?.quantitativeValue || 0,
                        qualitativePercent: item.quantityAndQuality?.qualitativePercent || 0,
                        qualitativeValue: item.quantityAndQuality?.qualitativeValue || 0,
                    })
                });
                setProcessors(transformedData);
            })
            .catch((error) => console.error('Error fetching processors:', error));

        apiService.getMerchantStatistics({ startDate, endDate, merchantId: selectedMerchant, midId: selectedMID })
            .then((response) => {
                const transformedData = response.data.map((item: any, index: number) => ({
                    id: item.id,
                    color: colorsPalette[index],
                    name: item.name,
                    quantitativePercent: item.quantityAndQuality?.quantitativePercent || 0,
                    quantitativeValue: item.quantityAndQuality?.quantitativeValue || 0,
                    qualitativePercent: item.quantityAndQuality?.qualitativePercent || 0,
                    qualitativeValue: item.quantityAndQuality?.qualitativeValue || 0,
                }));
                setMerchants(transformedData);
            })
            .catch((error) => console.error('Error fetching merchants:', error));

        apiService.getMidStatistics({ startDate, endDate, merchantId: selectedMerchant, midId: selectedMID })
            .then((response) => {
                const transformedData = response.data.map((item: any, index: number) => ({
                    id: item.id,
                    color: colorsPalette[index],
                    name: item.name,
                    quantitativePercent: item.quantityAndQuality?.quantitativePercent || 0,
                    quantitativeValue: item.quantityAndQuality?.quantitativeValue || 0,
                    qualitativePercent: item.quantityAndQuality?.qualitativePercent || 0,
                    qualitativeValue: item.quantityAndQuality?.qualitativeValue || 0,
                }));
                setMIDS(transformedData);
            })
            .catch((error) => console.error('Error fetching mids:', error));
    }, [user?.id, startDate, endDate, selectedMerchant, selectedMID]);

    useEffect(() => {
        setSelectedMID('');
        if (!isMerchant && selectedMerchant) {
            apiService.getMidsByMerchantAndProcessor({ merchantId: selectedMerchant, processorId: null })
                .then((response) => {
                    const transformedMids = response.data.map((mid: any) => ({
                        label: mid.midName || mid.midCode || 'Unknown',
                        value: mid.id,
                    }));
                    setMidOptions([{
                        label: 'Reset',
                        value: '',
                    }, ...transformedMids]);
                })
                .catch((error) => console.error('Error fetching mids:', error));
        } else {
            setMidOptions([])
        }

    }, [selectedMerchant])


    const handleDataUpdate = (startDate?: string, endDate?: string, merchantId?: any) => {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    return (
        <div className='reports-time-page page-content'>
                    <div className="page-header flex-row flex-gap-20 flex-align-start-center width-100 flex-wrap">
                        <div className='page-title'>
                            Reports Merchant
                        </div>
                        
                        
                    </div>
                    
                <div className='flex-row width-100 flex-align-start-start flex-wrap flex-gap-30'>
                <TimeFilters transactionsNo={transactionsNumber} handleFilters={handleDataUpdate} />
                <AppSelect
                            theme='filter'
                            options={merchantOptions}
                            value={selectedMerchant}
                            placeholder='Select Merchant'
                            onChange={(value) => { setSelectedMerchant(value) }}
                        />
                        <AppSelect
                            theme='filter'
                            value={selectedMID}
                            options={midOptions}
                            placeholder='Select MID'
                            onChange={(value) => setSelectedMID(value)}
                        />
                <div className='flex-row flex-align-end-end flex-1'>
                            <ReportsMerchantPartFilteredDataItem
                                total={transactionsSummary?.totalAmount || 0}
                                noTransactions={transactionsSummary?.transactionCount || 0}
                                filters={{ startDate, endDate, merchantId: selectedMerchant, midId: selectedMID }}
                            />
                    </div>
            </div>

            <StatisticSection title='Status Statistics'>
                <StatisticsSectionChartType data={transactionsStatus || []} />
            </StatisticSection>
            <StatisticSection title='Card Brand Statistics'>
                <StatisticsSectionChartType data={cardTypes || []} />
            </StatisticSection>
            <StatisticSection title='Processors Statistics'>
                <StatisticsSectionBarChartType data={processors || []} />
            </StatisticSection>
            <StatisticSection title='MIDs Statistics'>
                <StatisticsSectionBarChartType data={mids || []} />
            </StatisticSection>
        </div>
    )
}

export default ReportsMerchantPage