import { Statistic } from '../types/statistics.types';
import { formatAmount } from '../utils/number-utils';
import './legend-item.scss';

interface LegendItemProps extends Statistic {

}

const LegendItem: React.FC<LegendItemProps> = ({color, name, type, qualitativePercent, qualitativeValue, quantitativePercent, quantitativeValue}) => {
    return (
        <div className='legend-item flex-column flex-gap-15 flex-align-start-start'>
            <div className='flex-column flex-gap-5 flex-align-start-start'>
                <div className='color' style={{backgroundColor: color}}></div>
                <div className='name' style={{color: color}}>{name}</div>
                { type &&
                    <div className='type'>{type}</div>
                }
            </div>
            <div className='flex-column flex-gap-5 flex-align-start-start'> 
                <span className='label'>Rate: <span className='bolded'>{quantitativePercent}%</span></span>
                <span className='quantitative-value value'>{quantitativeValue} transactions</span>
            </div>
            <div className='flex-column flex-gap-5 flex-align-start-start'>
                <span className='label'>Amount: <span className='qualitative-value value'>${formatAmount(qualitativeValue)}</span></span>
            </div>
        </div>
    )
}

export default LegendItem;