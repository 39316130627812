import { useNavigate } from 'react-router-dom';
import TimeFilters from '../../../../components/time-filters';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { apiService } from '../../../../services/api.service';
import { AppStore } from '../../../../store/store';
import { UserState } from '../../../../store/user.slice';
import dayjs from 'dayjs';
import ReportDailyTable from './reports-daily-table';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import './reports-daily-processing.scss';
import ReportsDailyMobilePartWeekInfo from './reports-daily-mobile.part.week-info';
import ReportsDailyMobilePartDayInfo from './reports-daily-mobile.part.day-info';
import { LabelValue } from '../../../../types/label-value.types';
import AppSelect from '../../../../components/form/base/app-select';


dayjs.extend(utc);
dayjs.extend(timezone);

const timeZone: string = 'Asia/Singapore';

interface Merchant {
  merchantId: string;
  merchantName: string;
  approvedTransactionsNo: number;
  approvedTransactionsAmount: number;
  approvedTransactionsPercent: number | null;
  declinedTransactionsNo: number;
  declinedTransactionsAmount: number;
  declinedTransactionsPercent: number | null;
}

interface DayData {
  dayNo: number;
  dayName: string;
  merchants: Merchant[];
}


const ReportDailyProcessing: React.FC = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState<string>(dayjs().tz(timeZone).startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState<string | undefined>();
  const [merchantsData, setMerchantsData] = useState<DayData[] | undefined>();
  const [isCurrentMonth, setIsCurrentMonth] = useState(true);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const [merchantOptions, setMerchantOptions] = useState<LabelValue[]>([]);
  const [processorsOptions, setProcessorsOptions] = useState<LabelValue[]>([]);
  const [midOptions, setMidOptions] = useState<LabelValue[]>([]);
  const [selectedMID, setSelectedMID] = useState<string | null>(null);
  const [selectedMerchant, setSelectedMerchant] = useState<string | null>(null);
  const [selectedProcessor, setSelectedProcessor] = useState<string | null>(null);
  const { user } = useSelector<AppStore, UserState>((state) => state.user);

  const daysInMonth = dayjs(startDate).daysInMonth();
  const isMerchant = (user?.roles?.includes("merchant") || user?.roles?.includes("bas_merchant_view")) ?? false;

  useEffect(() => {
    apiService
      .getAllMerchants()
      .then((response) => {
        const transformedMerchants = response.data.map((merchant: any) => ({
          label: merchant.lastName || merchant.lastName || 'Unknown',
          value: merchant.id,
        }));
        setMerchantOptions([{
          label: 'Reset',
          value: '',
        }, ...transformedMerchants]);
      })
      .catch((error) => console.error('Error fetching merchants:', error));


      apiService
      .getAllProcessors()
      .then((response) => {
          const transformedProcessors = response.data.map((processor: any) => ({
              label: processor.processorName || processor.processorType || 'Unknown',
              value: processor.id,
          }));
          setProcessorsOptions([{
              label: 'Reset',
              value: '',
          }, ...transformedProcessors]);
      })
      .catch((error) => console.error('Error fetching processors:', error));
  }, [])

  useEffect(() => {
    setSelectedMID('');
    if (!isMerchant && selectedMerchant) {
      apiService.getMidsByMerchantAndProcessor({ merchantId: selectedMerchant, processorId: selectedProcessor })
        .then((response) => {
          const transformedMids = response.data.map((mid: any) => ({
            label: mid.midName || mid.midCode || 'Unknown',
            value: mid.id,
          }));
          setMidOptions([{
            label: 'Reset',
            value: '',
          }, ...transformedMids]);
        })
        .catch((error) => console.error('Error fetching mids:', error));
    } else {
        setMidOptions([])
    }

  }, [selectedMerchant, selectedProcessor])

  useEffect(() => {
    if (isMerchant)
      navigate('/app')

    if (startDate) {
      const yearMonthDate = dayjs(startDate).tz(timeZone).format('YYYY-MM');
      setIsCurrentMonth(dayjs(startDate).tz(timeZone).isSame(dayjs(), 'month') ? true : false)
      setMerchantsData(undefined);
      apiService.getDailyStatistics({ yearMonthDate, merchantId: selectedMerchant || undefined, processorId: selectedProcessor || undefined, midId: selectedMID || undefined})
        .then((response) => {
          const apiData = response.data;
          setMerchantsData(apiData);
        })
        .catch((e) => console.log(e));
    }
  }, [startDate, selectedMerchant, selectedProcessor, selectedMID]);

  useEffect(() => {
    setSelectedMID('');
    if (!isMerchant && selectedMerchant) {
    apiService.getMidsByMerchantAndProcessor({ merchantId: selectedMerchant, processorId: selectedProcessor })
        .then((response) => {
            const transformedMids = response.data.map((mid: any) => ({
                label: mid.midName || mid.midCode || 'Unknown',
                value: mid.id,
            }));
            setMidOptions([{
                label: 'Reset',
                value: '',
            }, ...transformedMids]);
        })
        .catch((error) => console.error('Error fetching mids:', error));
    } else {
      
    }

}, [selectedMerchant, selectedProcessor])


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleDataUpdate = (newStartDate?: string, newEndDate?: string) => {
    setStartDate(newStartDate ?? dayjs().startOf('month').format('YYYY-MM-DD'));
    setEndDate(newEndDate);
  };

  return (
    <div className="reports-time-page page-content">
      <div className="page-header flex-row flex-gap-20 flex-align-start-center">
        <div className="page-title">Reports Daily Processing</div>
      </div>
      <div className='flex-column flex-gap-20'>
        <TimeFilters
          handleFilters={handleDataUpdate}
          dontShowTransactionsNo
          filterOnlyMonth
        />
        <div className='flex-row flex-wrap flex-gap-15'>
          <AppSelect
            theme='filter'
            value={selectedMerchant}
            options={merchantOptions}
            placeholder='Select Merchant'
            onChange={(value) => setSelectedMerchant(value)}
          />
           <AppSelect
                theme='filter'
                value={selectedProcessor}
                options={processorsOptions}
                placeholder='Select Processor'
                onChange={(value) => setSelectedProcessor(value)}
            />
          <AppSelect
            theme='filter'
            value={selectedMID}
            options={midOptions}
            placeholder='Select MID'
            onChange={(value) => setSelectedMID(value)}
          />
        </div>
      </div>
      {merchantsData && (
        <div className="merchants-data-content">
          {isMobile ? (
            <>
              <ReportsDailyMobilePartDayInfo data={merchantsData} isCurrentMonth={isCurrentMonth} />
              <ReportsDailyMobilePartWeekInfo data={merchantsData} />
            </>
          ) : (
            <ReportDailyTable
              data={merchantsData}
              daysInMonth={daysInMonth}
              startDate={startDate}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ReportDailyProcessing;
