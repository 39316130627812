import axios, { AxiosInstance, AxiosResponse, } from 'axios';
import { attachAxiosApiInterceptors } from '../utils/axios-utils';
import { Dispatch } from '@reduxjs/toolkit';
import { ApiPageInfo, DailyProcessingFilters, TransactionFilters } from './api.service-types';
import { UserModel } from '../models';
import { Processor } from '../types/processor.types';
import { StatusType } from '../types/statistics.types';
import { PaymentInformation } from '../models/3ds.model';

class ApiService {
  private readonly client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      // #TODO change url
      baseURL: 'https://backend.paybridge.services/',
      timeout: 300000,
      headers: {
        'Content-Type': 'application/json',
      }
    });

  }

  attachDispatchToAxiosApi(dispatch: Dispatch<any>) {
    attachAxiosApiInterceptors(this.client, dispatch)
  }


  getUserDetails(userId: string): Promise<AxiosResponse<UserModel>> {
    return this.client.get(`/auth/${userId}`)
  }


  //PROCESSOR

  createProcessor(processor: Omit<Processor, 'id'>) {
    return this.client.post('/processor/create', processor)
  }

  getAllProcessors() {
    return this.client.get('/processor/getAll');
  }


  //MERCHANT

  createMerchant(merchant: any) {
    return this.client.post('/merchant/create', merchant)
  }

  getMerchantById(merchantId: string) {
    return this.client.get(`/merchant/getById/${merchantId}`)
  }

  updateMerchant(merchant: any) {
    return this.client.put('/merchant/update', merchant)
  }

  getMidsByMerchantId(merchantId: string) {
    return this.client.get(`/mid/getByMerchantId/${merchantId}`)
  }

  getMidLimitById(id: string) {
    return this.client.get(`/mid/getMidLimitById/${id}`)
  }

  getAllMerchants() {
    return this.client.get('/merchant/getAll')
  }

  disableMerchant(id: string) {
    return this.client.put(`merchant/disableMerchant/${id}`)
  }

  getMidsByMerchantAndProcessor({merchantId, processorId } :  { merchantId: string | null, processorId: string | null}) {
    const queryString = this.getQueryParams({merchantId,  processorId});
    return this.client.get(`mid/getMidsEnum${queryString}`)
  }


  //TRANSACTIONS

  getTransactions(filters?: Partial<TransactionFilters>, pageInfo?: ApiPageInfo) {
    const queryString = this.getQueryParams(pageInfo);
    return this.client.post(`/transaction/user/transactionsList${queryString}`, filters)
  }

  getTransactionById(id: string) {
    return this.client.get(`/transaction/user/getById/${id}`)
  }

  getTransactionsSummary(filters?: Partial<TransactionFilters>) {
    return this.client.post(`/transaction/user/summary`, filters)
  }

  getTransactionsSummaryToday() {
    return this.client.get('/transaction/user/summaryToday') 
  }

  getRecentTransactions() {
    return this.client.get('/transaction/user/getRecentTransactions')
  }

  refundTransaction(transactionId: string) {
    return this.client.post(`/payment/refundPayment/${transactionId}`)
  }

  addRefundReason(transactionId: string, refundReason: string) {
    return this.client.put(`/transaction/addRefundReason/${transactionId}`, { refundReason: refundReason })
  }

  updateTransactionStatus(transactionId: string, newStatus: StatusType) {
    return this.client.put(`/transaction/admin/updateStatus/${transactionId}/${newStatus}`)
  }

  refreshTransaction(transactionId: string) {
    return this.client.put(`/payment/updatePayment/${transactionId}`)
  }


  getTransactionStatus(transactionId: string) {
    return this.client.get(`/payment/getTransactionStatusAsString/${transactionId}`)
  }

  getBillingDetails(transactionId: string) {
    return this.client.get(`/transaction/user/billingDetails/${transactionId}`)
  }

  getAllCustomers() {
    return this.client.get('transaction/user/getAllCustomersList')
  }

  getAllCardTypes() {
    return this.client.get('transaction/user/getAllCardTypesList');
  }


  // GENERATE DOCUMENTS 

  generateSettlementExcel(filters: Partial<TransactionFilters>) {
    return this.client.post(`/documents/generateSettlementReportExcel`, filters, {
      responseType: 'arraybuffer',
    })
  }

  generateExcel(filters: Partial<TransactionFilters>) {
    return this.client.post(`/documents/transactionExcel`,filters, {
      responseType: "arraybuffer",
    })
  }

  generatePdf(filters: Partial<TransactionFilters>) {
    return this.client.post(`/documents/generateSettlementReport`, filters ,{
      responseType: "arraybuffer",
    })
  }


  //STATISTICS

  getSuccessAndErrorStatistics(filters: Partial<TransactionFilters>) {
    return this.client.post(`/kpi/getSuccessAndErrorsStatistics`,filters);
  }


  getTransactionsStatusStatistics(filters: Partial<TransactionFilters>) {
    const queryString = this.getQueryParams(filters);
    return this.client.get(`/kpi/getTransactionsStatusStatistics${queryString}`);
  }

  getProcessorStatistics(filters?: Partial<TransactionFilters>) {
    const queryString = this.getQueryParams(filters);
    return this.client.get(`/kpi/getProcessorStatistics${queryString}`);
  }

  getMerchantStatistics(filters?: Partial<TransactionFilters>) {
    const queryString = this.getQueryParams(filters);
    return this.client.get(`/kpi/getMerchantStatistics${queryString}`);
  }

  getCardTypeStatistics(filters: Partial<TransactionFilters>) {
    const queryString = this.getQueryParams(filters);
    return this.client.get(`/kpi/getCardTypeAndCardOwnerStatistics${queryString}`);
  }
  getMerchantsTransactionsPercents() {
    return this.client.get(`/kpi/getMerchantsTransactionsPercents`);
  }

  getMidStatistics(filters: Partial<TransactionFilters>) {
    const queryString = this.getQueryParams(filters);
    return this.client.get(`/kpi/getMidStatistics${queryString}`)
  }

  getDailyStatistics(filters: Partial<DailyProcessingFilters>) {
    const queryString = this.getQueryParams(filters);
    return this.client.post(`/kpi/dashboard${queryString}`)
  }



  //CYBERSOURCE-INTEGRATION

  paymentFinalStep(merchantId: string, midName : string,  ) {
    return this.client.post(`cyb/paymentFinalStep/${merchantId}/${midName}`)
  }

  payerSetup(apiKey: string, merchantId: string, midName: string, paymentInformation: PaymentInformation) {
    return this.client.post(`cyb/payerSetup/${merchantId}/${midName}`, paymentInformation)
  }

  payerEnrollment(merchantId: string, midName: string) {
    return this.client.post(`cyb/payerEnrollment/${merchantId}/${midName}`)
  }

  payerAuthenticationValidation(merchantId: string, midName: string) {
    return this.client.post(`cyb/payerEnrollment/${merchantId}/${midName}`)
  }



  private getQueryParams(params: any, addQuestionMark: boolean = true) {
    if (!params || typeof params !== 'object') {
      return '';
    }

    const queryString = Object.keys(params)
      .filter(key => params[key] !== undefined && params[key] !== null && params[key] !== '')
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&');

    return queryString && addQuestionMark ? `?${queryString}` : queryString;
  }

}

const apiService = new ApiService();
export { apiService };