import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { threeDSecureService } from "../../../../services/3dsecure.service";
import { ClientReferenceInformation, ConsumerAuthenticationInformation, OrderInformation, PayerEnrollmentRequest, PayerFinalStepRequest, PayerValidationRequest, PaymentInformation, ProcessingInformation } from "../../../../models/3ds.model";
import "./step-1-page.scss";
import LoadingSpinner from "../../../../components/loading-spinner";
import { decryptService } from "../../../../services/decrypt.service";
import Button from "../../../../components/button";




type enrollmentStatus = 'AUTHENTICATION_SUCCESSFUL' | 'PENDING_AUTHENTICATION' | 'AUTHENTICATION_FAILED';


interface Step1Form {
    jwt: string;
    deviceDataCollectionUrl: string;
}


const Step1Page: React.FC = () => {

    const location = useLocation();
    const fullPath = location.pathname;


    const parts = fullPath.split('/');
    const transactionId = parts.slice(2)[0];
    const encryptedData = parts.slice(3).join('/');

    const [isLoading, setIsLoading] = useState(true);


    const [authenticationTransactionId, setAuthenticationTransactionId] = useState();

    const [enrollmentStatus, setEnrollmentStatus] = useState<enrollmentStatus>();

    const [decryptedData, setDecryptedData] = useState<any>();

    const [isChallengeDone, setIsChallengeDone] = useState(false);




    const form = useForm<Step1Form>({
        defaultValues: {
            jwt: "",
            deviceDataCollectionUrl: "",
        },
    });



    const cardinalFormRef = useRef<HTMLFormElement>(null);
    const iframeRef = useRef<HTMLIFrameElement>(null);

    const stepUpFormRef = useRef<HTMLFormElement>(null);
    const stepUpiframeRef = useRef<HTMLIFrameElement>(null);


    useEffect(() => {
        if (encryptedData) {
            try {
                const decryptedDataJson = decryptService.decryptSensitiveData(encryptedData);
                const dataObject = JSON.parse(decryptedDataJson);
                setDecryptedData(dataObject)
                const paymentInformation: PaymentInformation = {
                    card: {
                        expirationYear: dataObject.expYear,
                        expirationMonth: dataObject.expMonth,
                        securityCode: dataObject.cvcCode,
                        number: dataObject.ccn
                    }
                }
                threeDSecureService.payerSetup(dataObject.apiKey, dataObject.merchantIdentifier, dataObject.midName, transactionId, { paymentInformation: paymentInformation }).then((response) => {
                    const responseData = response.data
                    setDecryptedData({ ...dataObject, ...response.data })
                    if (responseData) {
                        form.reset({
                            jwt: responseData.consumerAuthenticationInformation.accessToken,
                            deviceDataCollectionUrl: responseData.consumerAuthenticationInformation.deviceDataCollectionUrl,
                        });

                        if (cardinalFormRef.current) {
                            cardinalFormRef.current.action =
                                responseData.consumerAuthenticationInformation.deviceDataCollectionUrl;
                            const jwtInput = cardinalFormRef.current.querySelector(
                                'input[name="JWT"]'
                            ) as HTMLInputElement;
                            if (jwtInput) jwtInput.value = responseData.consumerAuthenticationInformation.accessToken;
                            cardinalFormRef.current.submit();

                        }
                    }
                })
            } catch (e) {
                console.log(e);
                window.location.href = decryptedData.redirectUrl;
            }
        }
    }, [location, form]);


    useEffect(() => {
        const catchMessage = async (event: MessageEvent) => {
            console.log('Final:', event.origin);

            const orderInformation: OrderInformation = {
                billTo: {
                    country: decryptedData.country,
                    lastName: decryptedData.lastName,
                    address1: decryptedData.address,
                    postalCode: decryptedData.zipCode,
                    locality: decryptedData.city,
                    administrativeArea: decryptedData.state,
                    firstName: decryptedData.firstName,
                    phoneNumber: decryptedData.phoneNumber,
                    district: decryptedData.city,
                    buildingNumber: '0',
                    email: decryptedData.email
                },
                amountDetails: {
                    totalAmount: decryptedData.amount,
                    currency: decryptedData.currency
                }
            }

            const paymentInformation: PaymentInformation = {
                card: {
                    expirationMonth: decryptedData.expMonth,
                    expirationYear: decryptedData.expYear,
                    securityCode: decryptedData.cvcCode,
                    number: decryptedData.ccn
                }
            }

            const clientReferenceInformation: ClientReferenceInformation = decryptedData.clientReferenceInformation;


            if (event.origin == 'https://centinelapi.cardinalcommerce.com' || event.origin == 'https://centinelapistag.cardinalcommerce.com') {


                const consumerAuthenticationInformation: ConsumerAuthenticationInformation = { ...decryptedData.consumerAuthenticationInformation, returnUrl: decryptedData.internalPostbackUrl }


                const requestBody: PayerEnrollmentRequest = {
                    orderInformation,
                    paymentInformation,
                    consumerAuthenticationInformation,
                    clientReferenceInformation
                }

                try {


                    const enrollmentResponse = await threeDSecureService.payerEnrollment(decryptedData.apiKey, decryptedData.merchantIdentifier, decryptedData.midName, transactionId, requestBody);

                    const enrollmentResponseData = enrollmentResponse.data;


                    setEnrollmentStatus(enrollmentResponseData.status);


                    setAuthenticationTransactionId(enrollmentResponseData.consumerAuthenticationInformation.authenticationTransactionId)

                    if (enrollmentResponseData.status === 'AUTHENTICATION_SUCCESSFUL') {
                        const consumerAuthenticationInformationFinalStep: ConsumerAuthenticationInformation = {
                            ...consumerAuthenticationInformation,
                            xid: enrollmentResponseData.consumerAuthenticationInformation.xid,
                            cavv: enrollmentResponseData.consumerAuthenticationInformation.cavv,
                            ucafCollectionIndicator: enrollmentResponseData.consumerAuthenticationInformation.ucafCollectionIndicator,
                            ucafAuthenticationData: enrollmentResponseData.consumerAuthenticationInformation.ucafAuthenticationData,
                            directoryServerTransactionId: enrollmentResponseData.consumerAuthenticationInformation.directoryServerTransactionId,
                            paSpecificationVersion: enrollmentResponseData.consumerAuthenticationInformation.specificationVersion
                        }

                        const processingInformation: ProcessingInformation = {
                            commerceIndicator: enrollmentResponseData.consumerAuthenticationInformation.ecommerceIndicator,
                            capture: true,
                        }

                        const requestFinalStepBody: PayerFinalStepRequest = {
                            clientReferenceInformation,
                            paymentInformation,
                            consumerAuthenticationInformation: consumerAuthenticationInformationFinalStep,
                            orderInformation,
                            processingInformation,
                            postbackUrl: decryptedData.postbackUrl

                        }

                        const finalStepResponse = await threeDSecureService.payerFinalStep(decryptedData.apiKey, decryptedData.merchantIdentifier, decryptedData.midName, transactionId, requestFinalStepBody)
                        setIsChallengeDone(true);

                        window.location.href = decryptedData.redirectUrl;



                    } else {

                        if (stepUpFormRef.current) {
                            stepUpFormRef.current.action =
                                enrollmentResponseData.consumerAuthenticationInformation.stepUpUrl;
                            const jwtInput = stepUpFormRef.current.querySelector(
                                'input[name="JWT"]'
                            ) as HTMLInputElement;
                            if (jwtInput) jwtInput.value = enrollmentResponseData.consumerAuthenticationInformation.accessToken;
                            stepUpFormRef.current.submit();
                            setIsLoading(false)
                        }
                    }
                } catch (e) {
                    window.location.href = decryptedData.redirectUrl;


                }
            } else if (event.origin == 'https://backend.paybridge.services') {


                const consumerAuthenticationInformation: ConsumerAuthenticationInformation = { ...decryptedData.consumerAuthenticationInformation, returnUrl: decryptedData.internalPostbackUrl, authenticationTransactionId: authenticationTransactionId }

                const requestBody: PayerValidationRequest = {
                    paymentInformation,
                    consumerAuthenticationInformation
                }


                const validationResponse = await threeDSecureService.payerValidation(decryptedData.apiKey, decryptedData.merchantIdentifier, decryptedData.midName, transactionId, requestBody)

                const validationResponseData = validationResponse.data;
                console.log(validationResponseData, "Validation Response")
                const processingInformation: ProcessingInformation = {
                    commerceIndicator: validationResponseData.consumerAuthenticationInformation.indicator,
                    capture: true,
                    actionList: ['VALIDATE_CONSUMER_AUTHENTICATION']
                }

                const consumerAuthenticationInformationFinalStep: ConsumerAuthenticationInformation = {
                    ...consumerAuthenticationInformation,
                    xid: validationResponseData.consumerAuthenticationInformation.xid,
                    cavv: validationResponseData.consumerAuthenticationInformation.cavv,
                    ucafCollectionIndicator: validationResponseData.consumerAuthenticationInformation.ucafCollectionIndicator,
                    ucafAuthenticationData: validationResponseData.consumerAuthenticationInformation.ucafAuthenticationData,
                    directoryServerTransactionId: validationResponseData.consumerAuthenticationInformation.directoryServerTransactionId,
                    paSpecificationVersion: validationResponseData.consumerAuthenticationInformation.specificationVersion
                }

                const requestFinalStepBody: PayerFinalStepRequest = {
                    clientReferenceInformation,
                    paymentInformation,
                    consumerAuthenticationInformation: consumerAuthenticationInformationFinalStep,
                    orderInformation,
                    processingInformation,
                    postbackUrl: decryptedData.postbackUrl
                }

                const finalStepResponse = await threeDSecureService.payerFinalStep(decryptedData.apiKey, decryptedData.merchantIdentifier, decryptedData.midName, transactionId, requestFinalStepBody);

                setIsChallengeDone(true);

                window.location.href = decryptedData.redirectUrl;

            }
        };
        if (decryptedData) {
            window.addEventListener('message', catchMessage);

            return () => {
                window.removeEventListener('message', catchMessage);
            };
        }
    }, [authenticationTransactionId, decryptedData])


    const handleRedirect = () => {
        if (window.parent !== window) {
            window.parent.history.back();
        } else {
            console.error("The app is not inside an iframe. Can't navigate the parent window.");
        }
    }

    const onSubmit = (data: Step1Form) => {
        console.log("Form Submitted:", data);
    };

    return (
        <div className="step-1-page">
            <div>
            </div>

            {isLoading && !enrollmentStatus &&
                <div className="loader-container">
                    <LoadingSpinner />
                </div>
            }

            <div className="form-container" style={{ display: "none" }}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <label>JWT:</label>
                    <input
                        {...form.register("jwt")}
                        placeholder="JWT:"
                        required
                    />
                    <label>Device Data Collection URL:</label>
                    <input
                        {...form.register("deviceDataCollectionUrl")}
                        placeholder="Device Data Collection URL:"
                        type="url"
                        required
                    />
                </form>
            </div>

            <iframe
                id="cardinal_collection_iframe"
                name="collectionIframe"
                ref={iframeRef}
                height="10"
                width="10"
                style={{ display: "none" }}
            ></iframe>
            <form
                id="cardinal_collection_form"
                method="POST"
                target="collectionIframe"
                ref={cardinalFormRef}
            >
                <input id="cardinal_collection_form_input" type="hidden" name="JWT" value="" />
            </form>


            {!isChallengeDone ?
                <>
                    <iframe
                        id="step_up_iframe"
                        name="stepUpIframe"
                        className="step-up-iframe"
                        ref={stepUpiframeRef}
                        height={500}
                        width={500}
                        style={{ display: enrollmentStatus == 'PENDING_AUTHENTICATION' ? 'block' : 'none' }}
                    >

                    </iframe>

                    <form
                        id="step_up_form"
                        method="POST"
                        target="stepUpIframe"
                        ref={stepUpFormRef}
                    >
                        <input id="step_up_form_input" type="hidden" name="JWT" value="" />
                    </form>
                </> :
                // <Button
                //     theme="form"
                //     label="Go Back"
                //     onClick={handleRedirect}
                // />
                <div> </div>

            }
        </div>
    );
};

export default Step1Page;
