import CryptoJS from "crypto-js";

class DecryptService {
    private readonly secret: string;

    constructor() {
        this.secret = 'sAIIpT4NmlZkWxAJuuv65g==';
    }

    decryptSensitiveData(encryptedBase64: string) {
            const key = CryptoJS.enc.Base64.parse(this.secret);
            const encryptedBytes = CryptoJS.enc.Base64.parse(encryptedBase64);

            const cipherParams = CryptoJS.lib.CipherParams.create({
                ciphertext: encryptedBytes,
            });

            const decrypted = CryptoJS.AES.decrypt(cipherParams, key, {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
            });

            const data = decrypted.toString(CryptoJS.enc.Utf8);
            return data;
    };
}

const decryptService = new DecryptService();

export { decryptService }