import AppRouter from './router/router';
import './i18n';
import { Provider } from 'react-redux';
import { store } from './store/store';
import AppInit from './AppInit';
import CacheBuster from 'react-cache-buster';

const currentVersion = process.env.REACT_APP_PAYBRIDGE_VERSION;

function App() {
  
  return (
    <>
      <CacheBuster
        currentVersion={currentVersion}
        isEnabled={true} 
        isVerboseMode={false} 
        metaFileDirectory={'.'} 
      >
        <Provider store={store}>
          <AppInit>
            <AppRouter />
          </AppInit>
        </Provider >
      </CacheBuster>
    </>
  );
}

export default App;
