import axios, { AxiosInstance, AxiosResponse } from "axios";
import { PayerEnrollmentRequest, PayerFinalStepRequest, PayerSetupRequest, PayerSetupResponse, PayerValidationRequest, } from "../models/3ds.model";

class ThreeDSecureService {
    private readonly client: AxiosInstance;

    constructor() {
        this.client = axios.create({
            baseURL: 'https://backend.paybridge.services/',
            // baseURL: 'http://192.168.100.106:8080/',
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                //   'x-api-key': 'P-FOck0xqdtoGKK7opbMItZT2Z2rJTzuatToNC0W9Mo',
            }
        });

    }

    payerSetup(apikey: string, merchantId: string, midName: string, transactionId: string, requestBody: PayerSetupRequest): Promise<AxiosResponse<PayerSetupResponse>> {
        return this.client.post(
            `cyb/payerSetup/${merchantId}/${midName}/${transactionId}`,
            requestBody,
            {
                headers: {
                    'x-api-key': apikey,
                },
            }
        );
    }

    payerEnrollment(apikey: string, merchantId: string, midName: string, transactionId: string, requestBody: PayerEnrollmentRequest) {
        return this.client.post(
            `/cyb/payerEnrollment/${merchantId}/${midName}/${transactionId}`,
            requestBody,
            {
                headers: {
                    'x-api-key': apikey,
                },
            }
        )
    }

    payerValidation(apikey: string, merchantId: string, midName: string,transactionId: string, requestBody: PayerValidationRequest) {
        return this.client.post(
            `/cyb/payerAuthenticationValidation/${merchantId}/${midName}/${transactionId}`,
            requestBody,
            {
                headers: {
                    'x-api-key': apikey,
                },
            }
        )
    }

    payerFinalStep(apikey: string, merchantId: string, midName: string, transactionId: string, requestBody: PayerFinalStepRequest) {
        return this.client.post(
            `/cyb/paymentFinalStep/${merchantId}/${midName}/${transactionId}`,
            requestBody,
            {
                headers: {
                    'x-api-key': apikey,
                },
            }
        )
    }

}

const threeDSecureService = new ThreeDSecureService();

export { threeDSecureService };