import React from 'react';
import dayjs from 'dayjs';
import { formatAmount } from '../../../../utils/number-utils';
import './reports-daily-table.scss';


interface Merchant {
  merchantId: string;
  merchantName: string;
  approvedTransactionsNo: number;
  approvedTransactionsAmount: number;
  approvedTransactionsPercent: number | null;
  declinedTransactionsNo: number;
  declinedTransactionsAmount: number;
}

interface DayData {
  dayNo: number;
  dayName: string;
  merchants: Merchant[];
}

interface ReportDailyTableProps {
  data: DayData[];
  daysInMonth: number;
  startDate: string;
}

const transformDataByWeeks = (data: DayData[], daysInMonth: number, startDate: string) => {
    const weeks: DayData[][] = [];
    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const firstDayIndex = (dayjs(startDate).startOf('month').day() + 6) % 7; 
    let currentWeek: DayData[] = [];
  
    for (let i = 0; i < firstDayIndex; i++) {
      currentWeek.push({ dayNo: 0, dayName: daysOfWeek[i], merchants: [] });
    }
  
    for (let dayNo = 1; dayNo <= daysInMonth; dayNo++) {
      const dayName = daysOfWeek[(firstDayIndex + dayNo - 1) % 7];
      const dayData = data.find((d) => d.dayNo === dayNo) || {
        dayNo,
        dayName,
        merchants: [],
      };
      currentWeek.push(dayData);
  
      if (currentWeek.length === 7 || dayNo === daysInMonth) {
        while (currentWeek.length < 7) {
          const dayIndex = currentWeek.length;
          currentWeek.push({
            dayNo: 0,
            dayName: daysOfWeek[dayIndex],
            merchants: [],
          });
        }
        weeks.push(currentWeek);
        currentWeek = [];
      }
    }
  
    return weeks;
  };

  const ReportDailyTable: React.FC<ReportDailyTableProps> = ({ data, daysInMonth, startDate }) => {
    const weeks = transformDataByWeeks(data, daysInMonth, startDate);
  
    return (
      <div className="report-daily-table">
        <table>
          <thead>
            <tr>
              <th>Merchant</th>
              <th>Type</th>
              {weeks[0]?.map((day, index) => (
                <th key={`day-header-${index}`} colSpan={3}>
                  {day.dayNo !== 0 ? `${day.dayName} ${day.dayNo}` : day.dayName}
                </th>
              ))}
              <th>Total Amount (Per Week)</th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              {weeks[0]?.map((_, index) => (
                <>
                  <th key={`amount-header-${index}`}>Amount</th>
                  <th key={`cc-header-${index}`}>CC</th>
                  <th key={`percent-header-${index}`}>%</th>
                </>
              ))}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {weeks.map((week, weekIndex) => (
              <React.Fragment key={`week-${weekIndex}`}>
                {data[0]?.merchants.map((merchant) => {
                  const weeklyTotal = week.reduce((sum, day) => {
                    const merchantData = day.merchants.find(
                      (m) => m.merchantId === merchant.merchantId
                    );
                    return sum + (merchantData?.approvedTransactionsAmount || 0);
                  }, 0);
  
                  return (
                    <React.Fragment key={`merchant-${merchant.merchantId}-${weekIndex}`}>
                      {/* Approved Row */}
                      <tr>
                        <td rowSpan={2}>{merchant.merchantName}</td>
                        <td>Approved</td>
                        {week.map((day, dayIndex) => {
                          const isInMonth = day.dayNo !== 0;
                          const merchantData = isInMonth
                            ? day.merchants.find((m) => m.merchantId === merchant.merchantId) || {
                                approvedTransactionsAmount: 0,
                                approvedTransactionsNo: 0,
                                approvedTransactionsPercent: 0,
                              }
                            : null;
  
                          return (
                            <>
                              <td className='approved' key={`approved-amount-${dayIndex}`}>
                                {isInMonth ? `$${formatAmount(merchantData?.approvedTransactionsAmount)} `: '-'}
                              </td>
                              <td key={`approved-cc-${dayIndex}`}>
                                {isInMonth ? merchantData?.approvedTransactionsNo : '-'}
                              </td>
                              <td key={`approved-percent-${dayIndex}`} rowSpan={2}>
                                {isInMonth ? merchantData?.approvedTransactionsPercent ?? '-' : '-'}
                              </td>
                            </>
                          );
                        })}
                        <td rowSpan={2}>${formatAmount(weeklyTotal)}</td>
                      </tr>
                      {/* Declined Row */}
                      <tr>
                        <td>Declined</td>
                        {week.map((day, dayIndex) => {
                          const isInMonth = day.dayNo !== 0;
                          const merchantData = isInMonth
                            ? day.merchants.find((m) => m.merchantId === merchant.merchantId) || {
                                declinedTransactionsAmount: 0,
                                declinedTransactionsNo: 0,
                              }
                            : null;
  
                          return (
                            <>
                              <td className='declined' key={`declined-amount-${dayIndex}`}>
                                {isInMonth ? `$ ${formatAmount(merchantData?.declinedTransactionsAmount)}` : '-'}
                              </td>
                              <td key={`declined-cc-${dayIndex}`}>
                                {isInMonth ? merchantData?.declinedTransactionsNo : '-'}
                              </td>
                            </>
                          );
                        })}
                      </tr>
                    </React.Fragment>
                  );
                })}
                {/* Weekly Total Row */}
                <tr style={{ fontWeight: 'bold', backgroundColor: '#dff0d8' }}>
                  <td colSpan={week.length * 3 + 2} style={{ textAlign: 'right' }}>
                    Grand Total (Per Week)
                  </td>
                  <td>${formatAmount(week.reduce((sum, day) => {
                    return (
                      sum +
                      day.merchants.reduce(
                        (merchantSum, merchant) =>
                          merchantSum + (merchant.approvedTransactionsAmount || 0),
                        0
                      )
                    );
                  }, 0))}</td>
                </tr>
                {/* Continuation Row */}
                {weekIndex < weeks.length - 1 && (
                  <tr style={{ backgroundColor: '#f9f9f9' }}>
                    <td></td>
                    <td></td>
                    {weeks[weekIndex + 1]?.map((day, dayIndex) => (
                      <td key={`continuation-day-${weekIndex}-${dayIndex}`} colSpan={3}>
                        {day.dayNo !== 0 ? `${day.dayName} ${day.dayNo}` : day.dayName}
                      </td>
                    ))}
                    <td></td>
                  </tr>
                )}
              </React.Fragment>
            ))}
            {/* Grand Total Row */}
            <tr style={{ fontWeight: 'bold', backgroundColor: '#f2dede' }}>
              <td colSpan={weeks[0]?.length * 3 + 2} style={{ textAlign: 'right' }}>
                Grand Total (All Weeks)
              </td>
              <td>
                ${formatAmount(weeks.reduce((grandTotal, week) => {
                  return (
                    grandTotal +
                    week.reduce((sum, day) => {
                      return (
                        sum +
                        day.merchants.reduce(
                          (merchantSum, merchant) =>
                            merchantSum + (merchant.approvedTransactionsAmount || 0),
                          0
                        )
                      );
                    }, 0)
                  );
                }, 0))}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
  
  
  
  
  
  
  
  
  
  export default ReportDailyTable
  

