import TransactionsPartDataItem from './transactions-page.part.data-item';
import TransactionsPartFilteredDataItem from './transactions-page.part.filtered-data-item';
import { ReactComponent as UserIcon } from '../../../assets/icons/user.svg';
import { ReactComponent as MerchantIcon } from '../../../assets/icons/merchant.svg';
import { ReactComponent as CardIcon } from '../../../assets/icons/processor-card.svg';
import { ReactComponent as RefreshIcon } from '../../../assets/icons/refresh.svg';
import TableContent from '../../../components/table-content';
import { useEffect, useState } from 'react';
import { apiService } from '../../../services/api.service';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppStore } from '../../../store/store';
import { UserState } from '../../../store/user.slice';
import TableFilters from '../../../components/table-filters';
import TimeFilters from '../../../components/time-filters';
import dateService from '../../../services/date.service';
import AppSelect from '../../../components/form/base/app-select';
import { StatusType } from '../../../types/statistics.types';
import { LabelValue } from '../../../types/label-value.types';
import AppModal from '../../../components/app-modal-transaction';
import Button from '../../../components/button';
import TablePagination from '../../../components/table-pagination';
import './transactions-page.scss';
import { errorService } from '../../../services/error.service';
import { generateOptions, TransactionSummary } from '../../../types/transaction.types';
import { formatAmount } from '../../../utils/number-utils';



interface Transaction {
    id: string;
    status: string;
    customerName: string;
    merchantName: string;
    processorName: string;
    amount: number;
    allowedActions: string[];
    date: string;
}


const TransactionsPage: React.FC = () => {
    const navigate = useNavigate();
    const [isModalVisible, setModalVisible] = useState(false);
    const [filters, setFilters] = useState({});
    const [transactions, setTransactions] = useState<Transaction[]>();
    const [modalData, setModalData] = useState({
        id: '',
        status: '',
        newStatus: '',
    });
    const { user } = useSelector<AppStore, UserState>((state) => state.user)
    const [refreshingTransactionId, setRefreshingTransactionId] = useState<string | null>(null);
    const [resetTrigger, setResetTrigger] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1)
    const [totalTransactions, setTotalTransactions] = useState();
    const [transactionStats, setTransactionStats] = useState({
        successTransactionsNo: 0,
        errorTransactionsNo: 0,
        successTransactionsAmount: 0,
        errorTransactionsAmount: 0,
        successTransactionsPercent: 0,
        errorTransactionsPercent: 0,
    });
    const [transactionsSummary, setTransactionsSummary] = useState<TransactionSummary>();

    const isMerchant = (user?.roles?.includes("merchant") || user?.roles?.includes("bas_merchant_view")) ?? false;

    const [refreshData, setRefreshData] = useState(false);

    const [isActionUsed, setIsActionUsed] = useState(false);

    const [isDesktop, setIsDesktop] = useState(false);

    useEffect(() => {
        const checkScreenSize = () => {
            setIsDesktop(window.innerWidth > 1024);
        };

        checkScreenSize();
        window.addEventListener('resize', checkScreenSize);

        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setRefreshData((prev) => !prev);
        }, 60000);

        return () => clearInterval(intervalId);
    }, [])

    const columnsMidTransactions = [
        {
            Header: 'Trace ID',
            accessor: 'id',
            Cell: ({ value }: { value: string }) => (
                <div>
                    <span
                        className="id-text"
                        onClick={() => {
                            const url = `/app/transactions/transaction/${value}`;
                            if (isDesktop) {
                                window.open(url, '_blank');
                            } else {
                                navigate(url);
                            }
                        }}
                    >{value}</span>
                </div>
            ),
        },
        {
            Header: 'Customer',
            accessor: 'customerName',
            Cell: ({ value, row }: { value: string; row: any }) => (
                <div className="flex-column flex-gap-5">
                    <div className="flex-row flex-gap-5 flex-align-start-center">
                        <UserIcon />
                        <span>{value}</span>
                    </div>
                    <div className="secondary-text accent-gradient-text">
                        {row.original.customerCardType} xxxx xxxx xxxx {row.original.customerLastFourCardDigits}
                    </div>
                </div>
            ),
        },
        {
            Header: 'Merchant',
            accessor: 'merchantName',
            Cell: ({ value, row }: { value: string; row: any }) => (
                <div className="flex-column flex-gap-5">
                    <div className="flex-row flex-gap-5 flex-align-start-center">
                        <MerchantIcon />
                        <span>{value}</span>
                    </div>
                    <div className="secondary-text accent-gradient-text">{row.original.transactionReferenceCode}</div>
                </div>
            ),
        },
        {
            Header: 'Channel',
            accessor: 'processorName',
            Cell: ({ value, row }: { value: string; row: any }) => {
                return (
                    <div className="flex-column flex-gap-5">
                        <div className="flex-row flex-gap-5 flex-align-start-center">
                            <CardIcon />
                            <span>{!isMerchant ? row.original.processorType : value}</span>
                        </div>
                        <div className="secondary-text accent-gradient-text">{!isMerchant && row.original.processorTransactionId}</div>
                    </div>
                )
            },
        },
        {
            Header: 'Value',
            accessor: 'amount',
            Cell: ({ value }: { value: string }) => (
                <span className="value-text accent-gradient-text">${formatAmount(+value)}</span>
            ),
        },
        {
            Header: () => (
                <div className='flex-row flex-align-center-center'>
                    Status
                </div>
            ),
            accessor: 'status',
            Cell: ({ value, row }: { value: string; row: any }) => {

                if (isMerchant) {
                    return (<div
                        className={`status ${value?.toLowerCase()}
                 flex-row flex-align-center-center`}
                        onClick={() => navigate(`/app/transactions/transaction/${row.original.id}`)}
                    >
                        <span>{value}</span>
                    </div>)
                }

                const { status, intermediateStatus } = row.original;

                const options: LabelValue<StatusType>[] = generateOptions(status, intermediateStatus);



                return (
                    <div className='flex-row flex-align-center-center width-100'>
                        {
                            options.length > 0 ? (
                                <AppSelect
                                    theme="status"
                                    options={options}
                                    placeholder={value}
                                    onChange={(newValue) => {
                                        setModalData({
                                            id: row.original.id,
                                            status: value,
                                            newStatus: newValue,
                                        });
                                        setModalVisible(true);
                                    }}
                                />
                            ) : (
                                <div
                                    className={`status ${value?.toLowerCase()} flex-row flex-align-center-center`}
                                >
                                    <span
                                        onClick={() => {
                                            if (value === StatusType.ERROR)
                                                navigate(`/app/transactions/transaction/${row.original.id}`)
                                        }}
                                    >{value}</span>
                                </div>

                            )
                        }
                    </div>
                );
            },
        },
        {
            Header: () => (
                <div className='flex-row flex-align-center-center'>
                    Time
                </div>
            ),
            accessor: 'date',
            Cell: ({ value }: { value: string }) => (
                <div className="date-text">{dateService.formatDateForTable(value)}</div>
            ),
        },
        {
            Header: () => (
                <div className="flex-row flex-align-center-center">
                    Log
                </div>
            ),
            accessor: "logs",
            Cell: ({ value, row }: { value: any; row: any }) => {
                if (row.original.status === StatusType.DECLINED && Array.isArray(value) && value.length > 0 && value[0]?.processorInternalStatus) {
                    return <div className="date-text">{value[0]?.processorInternalStatus}</div>;
                }
                return null;
            },
        },
        {
            Header: 'Actions',
            accessor: 'allowedActions',
            Cell: ({ value, row }: { value: string[]; row: any }) => {

                return (
                    <div className="flex-column flex-gap-5 flex-align-center-center">
                        {(row.original.status !== StatusType.CHARGEBACK && row.original.status !== StatusType.REFUNDED) &&
                            <>
                                <RefreshIcon
                                    className={`refresh-icon ${refreshingTransactionId === row.original.id ? 'rotate' : ''
                                        }`}
                                    onClick={() => {
                                        if (refreshingTransactionId !== row.original.id)
                                            handleRefreshClick(row.original.id)
                                    }}

                                />
                                {row.original.status == StatusType.APPROVED &&
                                    <div
                                        className='action refund'
                                        onClick={() => {
                                            setIsActionUsed(true)
                                            setModalData({
                                                id: row.original.id,
                                                status: StatusType.APPROVED,
                                                newStatus: StatusType.REFUNDED,
                                            });
                                            setModalVisible(true);
                                        }}>
                                        <span className='refunded'>REFUND</span>
                                    </div>
                                }
                            </>}
                    </div>
                )
            },
        },
    ];

    useEffect(() => {
        apiService.getSuccessAndErrorStatistics(filters)
            .then((response) => setTransactionStats(response.data))
            .catch((e) => console.log(e))



        apiService.getTransactionsSummary(filters)
            .then((response) => {
                setTransactionsSummary(response.data);
            })
            .catch((error) => console.error('Error fetching transactions summary:', error));
        const getTransactions = async () => {
            try {
                const pageInfo = {
                    page: currentPage - 1,
                    size: 10,
                };
                const response = await apiService.getTransactions(filters, pageInfo);
                return response.data;
            } catch (e) {

            }
        }


        getTransactions().then((result) => {
            if (result) {
                setTransactions(result.data)
                setTotalPages(result.totalPages);
                setTotalTransactions(result.totalElements)
            }
        })


    }, [user, filters, currentPage, refreshData])



    const handleTimeFilters = (startDate?: string, endDate?: string) => {
        setFilters((prev) => ({ ...prev, startDate, endDate }));
        setCurrentPage(1);
    };

    const handleTableFilters = (newFilters: Record<string, any>) => {
        setFilters((prev) => ({ ...prev, ...newFilters }));
        setCurrentPage(1);
    };

    const resetFilters = () => {
        setFilters({});
        setResetTrigger((prev) => !prev);
        setCurrentPage(1);
    };

    const handleRefreshClick = async (transactionId: string) => {
        setRefreshingTransactionId(transactionId);
        const startTime = Date.now();
        try {
            const response = await apiService.refreshTransaction(transactionId);
            const updatedStatus = response.data.status;

            setTransactions((prevTransactions) =>
                prevTransactions?.map((transaction) =>
                    transaction.id === transactionId
                        ? { ...transaction, status: updatedStatus }
                        : transaction
                )
            );
        } catch (e) {
            errorService.showApiError(e);
        } finally {
            const elapsedTime = Date.now() - startTime;
            const minimumDuration = 2000;

            const remainingTime = Math.max(0, minimumDuration - elapsedTime);

            setTimeout(() => {
                setRefreshingTransactionId(null);
                errorService.showSuccess('Success', 'Status has been updated');
            }, remainingTime);
        }
    };

    const handleUpdateStatus = async (transactionId: string, newStatus: StatusType) => {
        setRefreshingTransactionId(transactionId);
        const startTime = Date.now();
        try {
            const response = await apiService.updateTransactionStatus(transactionId, newStatus)
            const updatedStatus = response.data.status;
            setTransactions((prevTransactions) =>
                prevTransactions?.map((transaction) =>
                    transaction.id === transactionId
                        ? { ...transaction, status: updatedStatus }
                        : transaction
                )
            );
            errorService.showSuccess('Success', 'Status has been updated');
            setModalVisible(false);
        } catch (e) {
            errorService.showApiError(e);
            setModalVisible(false);
        } finally {
            const elapsedTime = Date.now() - startTime;
            const minimumDuration = 2000;

            const remainingTime = Math.max(0, minimumDuration - elapsedTime);
            setTimeout(() => {
                setRefreshingTransactionId(null);
            }, remainingTime);
        }
    };


    const handleRefundTransaction = async (transactionId: string) => {
        setModalVisible(false);
        try {
            const response = await apiService.refundTransaction(transactionId)
            if(response?.data?.success===true){
                errorService.showSuccess('Refund Initiated', 'Refund request has been successfully submitted.');
                handleUpdateStatus(transactionId, StatusType.REFUNDED)
            }else{
                errorService.showError("Refund Failed", "Refund cannot be proccesed.");
            }
     
        } catch (e) {
            errorService.showApiError(e);
        } finally {
            setIsActionUsed(false);
        }
    };

    const handleCancel = () => {
        setModalVisible(false);
    };

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };


    return (
        <div className="transactions-page page-content">

            <div className="page-header flex-row flex-gap-10 flex-align-start-center ">
                <div className='page-title'>
                    Transactions
                </div>

            </div>
            <div className='flex-row flex-gap-20 width-100 flex-wrap'>
                <TransactionsPartDataItem
                    theme='success'
                    total={transactionStats?.successTransactionsAmount}
                    noTransactions={transactionStats?.successTransactionsNo}
                    rate={transactionStats?.successTransactionsPercent}
                />
                <TransactionsPartDataItem
                    theme='error'
                    total={transactionStats?.errorTransactionsAmount}
                    noTransactions={transactionStats?.errorTransactionsNo}
                    rate={transactionStats?.errorTransactionsPercent}
                />
                <TransactionsPartFilteredDataItem
                    total={transactionsSummary?.totalAmount || 0}
                    noTransactions={transactionsSummary?.transactionCount || 0}
                    filters={filters}
                    dontShowPDF
                />
            </div>
            <TimeFilters
                handleFilters={handleTimeFilters}
                transactionsNo={totalTransactions}
                resetTrigger={resetTrigger} />
            <TableFilters
                onFiltersChange={handleTableFilters}
                resetTrigger={resetTrigger} />
            <Button theme='accent' label='Clear Filters' onClick={resetFilters} />
            <TablePagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
            <div className='inner-page flex-column flex-gap-30 width-100'>
                <TableContent columns={columnsMidTransactions} data={transactions || []}></TableContent>
            </div>
            <TablePagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
            <AppModal
                text={`Are you sure you want to change the STATUS of the transaction to `}
                id={modalData.id}
                confirmLabel="Confirm"
                cancelLabel="Cancel"
                confirmAction={isActionUsed ? handleRefundTransaction : handleUpdateStatus}
                cancelAction={handleCancel}
                isVisible={isModalVisible}
                status={modalData.newStatus as StatusType}
            />
        </div>
    )
}

export default TransactionsPage