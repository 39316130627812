import { createBrowserRouter, Navigate, RouterProvider, } from "react-router-dom";
import authRouter from "./public/auth-page.router";
import AppLayout from "./private/app-layout";
// import privateRouter from "./private/private-router";
import AuthGuard from "./public/auth-guard";
import privateRouter from "./private/private-router";
import Step1Page from "./private/payer-setup.tsx/step-1/step-1-page";
import AppErrorFallbackPage from "./error-fallback/error-fallback-page";

const appRouter = createBrowserRouter([
    {
      path: '',
      element: <Navigate to="/auth" replace />, 
    },
    {
      path: 'auth',
      element: <AuthGuard />, 
      children: authRouter, 
    },
    {
      path: 'app', 
      element: <AppLayout />,
      children: privateRouter
    },
    {
      path: '3ds',
      element: <Step1Page/>
    },
    {
      path: "3ds/*",
      element: (<AppErrorFallbackPage><Step1Page /></AppErrorFallbackPage>)
    }
  ]);

const AppRouter: React.FC = () => {
    return ( 
        <RouterProvider router={appRouter}/>
    )
}

export default AppRouter; 