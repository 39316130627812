import Tooltip from "@mui/material/Tooltip";
import { ReactElement } from "react";
import './app-tooltip.scss';

interface AppTooltipProps {
    children: ReactElement;
    title: string;
  }

const AppTooltip: React.FC<AppTooltipProps> = ({ children, title }) => {
  return (
    <Tooltip
      title={
        <div style={{ whiteSpace: 'pre-line', fontSize: 16 }}>
          {title}
        </div>
      }
    >
      {children}
    </Tooltip>
  );
};

export default AppTooltip;
